import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../utilities/Loader";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #01B051",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const button_style = {
  backgroundColor: "#01B051",
  color: "#fff",
  padding: "0.25rem",
  height: "3rem",
  borderRadius: "0.25rem",
  cursor: "pointer",
};

const UploadImages = () => {
  var isMobileScreen = 0;
  if (window.innerWidth < 450) {
    isMobileScreen = 1;
  }

  const webcamRef1 = useRef(null);
  const webcamRef2 = useRef(null);
  const webcamRef3 = useRef(null);
  const location = useLocation();
  const voterId = location?.state?.id;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openPhotoCap, setOpenPhotoCap] = useState(false);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [openPhoto, setOpenPhoto] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showSwitchCameraButton, setShowSwitchCameraButton] = useState(true);

  const [openPopUp, setOpenPopUp] = useState(true);
  const [facingMode, setFacingMode] = useState("user");
  const apiUrl = process.env.REACT_APP_API_URL;

  const captureImage = async (index) => {
    if (index === 0 && webcamRef1.current) {
      const imageSrc = webcamRef1.current.getScreenshot();
      setImage1(imageSrc);
      setOpen(false);
    } else if (index === 1 && webcamRef2.current) {
      const imageSrc = webcamRef2.current.getScreenshot();
      setImage2(imageSrc);
      setOpenPhotoCap(false);
    } else if (index === 2 && webcamRef3.current) {
      const imageSrc = webcamRef3.current.getScreenshot();
      setImage3(imageSrc);
      setOpenPhoto(false);
    }
  };

  const removeImage = (index) => {
    if (index === 0) {
      setImage1(null);
    } else if (index === 1) {
      setImage2(null);
    } else if (index === 2) {
      setImage3(null);
    }
  };
  const detectScreenWidth = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      setShowSwitchCameraButton(false);
    } else {
      setShowSwitchCameraButton(true);
    }
  };

  useEffect(() => {
    detectScreenWidth();
    // Event listener to update screen width on resize
    window.addEventListener("resize", detectScreenWidth);
    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", detectScreenWidth);
    };
  }, []);

  const handlePostData = async () => {
    if (!image1 || !image2 || !image3) {
      toast.error("Veuillez cliquer sur l'ID et la photo", { autoClose: 1000 });
      return;
    }
    setLoader(true);
    try {
      const formToBeSent = new FormData();
      formToBeSent.append("voterId", voterId);
      // this one for front side
      if (image1) {
        const blob1 = await fetch(image1).then((res) => res.blob());
        const file1 = new File([blob1], "identityFrontImage.png");
        formToBeSent.append("identityFrontSide", file1);
      }
      //this for rear side id fix the key as backend
      if (image2) {
        const blob2 = await fetch(image2).then((res) => res.blob());
        const file2 = new File([blob2], "identityRearImage.png");
        formToBeSent.append("identityRearSide", file2);
      }
      // this for userImage
      if (image3) {
        const blob3 = await fetch(image3).then((res) => res.blob());
        const file3 = new File([blob3], "userImage.png");
        formToBeSent.append("userImage", file3);
      }

      const response = await axios.put(
        `${apiUrl}/user/registerUser`,
        formToBeSent
      );

      if (response.data.statusCode === 200) {
        setLoader(false);
        localStorage.setItem("voterId", response.data.data._id);
        toast.success("Images téléchargées avec succès !", { autoClose: 1500 });
        setTimeout(() => {
          navigate("/validate/voterConfirm");
        }, 2000);
      }
    } catch (error) {
      setLoader(false);
      toast.error(
        "Erreur lors du téléchargement des images. Veuillez réessayer.",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const switchCamera = () => {
    setFacingMode((prevFacingMode) =>
      prevFacingMode === "user" ? "environment" : "user"
    );
  };

  const videoConstraints = {
    facingMode: facingMode,
  };

  return (
    <div className="w-full min-h-screen flex flex-col justify-between items-center">
      <Header isBackButtonEnables="true" backButtonPath="/validate" />
      {openPopUp ? (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-20 backdrop-blur-md">
          <div className="bg-white h-[23rem] rounded-xl border-2 border-[#01B051] max-w-lg w-full mx-4 md:mx-auto">
            <div className="p-8 h-full flex flex-col justify-between items-center">
              <h1 className="text-4xl text-gray-600 font-semibold text-center font-calibri mb-4">
                Clause de non-responsabilité
              </h1>
              <p className="text-xl text-center font-medium text-gray-700 font-calibri mb-4">
                Nous collectons vos données personnelles
              </p>
              <div className="flex justify-center space-x-4">
                <button
                  className="bg-green-600 text-white px-8 py-2 rounded-md text-base cursor-pointer font-calibri"
                  onClick={() => setOpenPopUp(false)}
                >
                  Accepter
                </button>
                <button
                  className="bg-red-600 text-white px-8 py-2 rounded-md text-base cursor-pointer font-calibri"
                  onClick={() => {
                    setOpenPopUp(false);
                    navigate("/validate");
                  }}
                >
                  Refuser
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`h-full flex flex-col gap-6 justify-center ${
            isMobileScreen ? "p-3" : "p-10"
          } rounded-md items-center bg-white`}
        >
          {loader && <Loader />}
          {/* <p className="w-full text-gray-800 text-left font-semibold font-calibri lg:text-2xl text-lg md:text-xl">
            Vérification d'identité
          </p> */}
          <div
            className={`w-full max-w-screen-lg bg-white rounded-md h-full flex flex-col gap-4 justify-center items-center ${
              isMobileScreen ? "p-3" : "p-8"
            }`}
          >
            <div className="w-full flex flex-col gap-4 justify-between items-center">
              <div className="w-full flex flex-col gap-2 justify-center">
                <div
                  className={`border-2 ${
                    isMobileScreen ? "p-3" : "p-10"
                  } rounded-md border-[#818080]`}
                >
                  {!image1 ? (
                    <div className="flex flex-col gap-4 justify-center items-center">
                      <p
                        onClick={() => setOpen(true)}
                        className="font-calibri px-4 py-1 md:px-4 md:py-1 lg:px-6 lg:py-1.5 w-full md:w-auto text-[#818080] text-base md:text-lg lg:text-2xl rounded-md cursor-pointer"
                      >
                        Prendre la photo recto de votre carte d’identité
                      </p>
                    </div>
                  ) : (
                    <div className="m-2 flex flex-col justify-center items-center gap-2">
                      <img
                        src={image1}
                        alt="Captured 1"
                        className="rounded-lg"
                      />
                      <div className="w-full flex justify-around items-center">
                        {/* <p  className="font-calibri px-4 py-1 md:px-6 md:py-2 lg:px-8 lg:py-1.5 w-full md:w-auto bg-[#01B051] text-white text-base md:text-lg lg:text-xl rounded-md">
                          ID Card
                        </p> */}
                        <button
                          onClick={() => removeImage(0)}
                          className="font-calibri px-4 py-1 md:px-6 md:py-2 lg:px-8 lg:py-1.5 w-full md:w-auto bg-red-600 text-white text-base md:text-lg lg:text-xl rounded-md"
                        >
                          Prendre une autre photo
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col gap-2 justify-center">
                <div
                  className={`border-2 ${
                    isMobileScreen ? "p-3" : "p-10"
                  } rounded-md border-[#818080]`}
                >
                  {!image2 ? (
                    <div className="flex flex-col gap-4 justify-center items-center">
                      <p
                        onClick={() => setOpenPhotoCap(true)}
                        className="font-calibri px-4 py-1 md:px-4 md:py-1 lg:px-6 lg:py-1.5 w-full md:w-auto text-[#818080] text-base md:text-lg lg:text-2xl rounded-md cursor-pointer"
                      >
                        Prendre la photo verso de votre carte d’identité
                      </p>
                    </div>
                  ) : (
                    <div className="m-2 flex flex-col justify-center items-center gap-2">
                      <img
                        src={image2}
                        alt="Captured 1"
                        className="rounded-lg"
                      />
                      <div className="w-full flex items-center justify-around">
                        {/* <p  className="font-calibri px-4 py-1 md:px-6 md:py-2 lg:px-8 lg:py-1.5 w-full md:w-auto bg-[#01B051] text-white text-base md:text-lg lg:text-xl rounded-md">
                          Photo
                        </p> */}
                        <button
                          onClick={() => removeImage(1)}
                          className="font-calibri px-4 py-1 md:px-6 md:py-2 lg:px-8 lg:py-1.5 w-full md:w-auto bg-red-600 text-white text-base md:text-lg lg:text-xl rounded-md"
                        >
                          Prendre une autre photo
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col gap-2 justify-center">
                <div
                  className={`border-2 ${
                    isMobileScreen ? "p-3" : "p-10"
                  } rounded-md border-[#818080]`}
                >
                  {!image3 ? (
                    <div className="flex flex-col gap-4 justify-center items-center">
                      <p
                        onClick={() => setOpenPhoto(true)}
                        className="font-calibri px-4 py-1 md:px-4 md:py-1 lg:px-6 lg:py-1.5 w-full md:w-auto text-[#818080] text-base md:text-lg lg:text-2xl rounded-md cursor-pointer"
                      >
                        Prendre un selfie
                      </p>
                    </div>
                  ) : (
                    <div className="m-2 flex flex-col justify-center items-center gap-2">
                      <img
                        src={image3}
                        alt="Captured 1"
                        className="rounded-lg"
                      />
                      <div className="w-full flex items-center justify-around">
                        {/* <p  className="font-calibri px-4 py-1 md:px-6 md:py-2 lg:px-8 lg:py-1.5 w-full md:w-auto bg-[#01B051] text-white text-base md:text-lg lg:text-xl rounded-md">
                          Photo
                        </p> */}
                        <button
                          onClick={() => removeImage(2)}
                          className="font-calibri px-4 py-1 md:px-6 md:py-2 lg:px-8 lg:py-1.5 w-full md:w-auto bg-red-600 text-white text-base md:text-lg lg:text-xl rounded-md"
                        >
                          Prendre une autre photo
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center items-center">
              {image1 && image2 && image3 && (
                <button
                  className="py-4 w-full max-w-[300px] bg-[#01B051] text-white font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl mx-auto rounded-2xl font-calibri"
                  onClick={handlePostData}
                >
                  Valider
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                <Webcam
                  audio={false}
                  ref={webcamRef1}
                  screenshotFormat="image/png"
                  videoConstraints={videoConstraints}
                  className=""
                />
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <div style={{ textAlign: "center" }} className="flex gap-2">
                  <Button
                    style={{ ...button_style, width: "60%", margin: "auto" }}
                    onClick={() => captureImage(0)}
                  >
                    Prendre une photo
                  </Button>
                  {showSwitchCameraButton && (
                    <Button
                      style={{ ...button_style, width: "60%", margin: "auto" }}
                      onClick={switchCamera}
                    >
                      Changer de caméra
                    </Button>
                  )}
                </div>
              </Typography>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openPhotoCap}
          onClose={() => setOpenPhotoCap(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openPhotoCap}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                <Webcam
                  audio={false}
                  ref={webcamRef2}
                  screenshotFormat="image/png"
                  videoConstraints={videoConstraints}
                  className=""
                />
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <div style={{ textAlign: "center" }} className="flex gap-2">
                  <Button
                    style={{ ...button_style, width: "60%", margin: "auto" }}
                    onClick={() => captureImage(1)}
                  >
                    Prendre une photo
                  </Button>
                  {showSwitchCameraButton && (
                    <Button
                      style={{ ...button_style, width: "60%", margin: "auto" }}
                      onClick={switchCamera}
                    >
                      Changer de caméra
                    </Button>
                  )}
                </div>
              </Typography>
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openPhoto}
          onClose={() => setOpenPhoto(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openPhoto}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                <Webcam
                  audio={false}
                  ref={webcamRef3}
                  screenshotFormat="image/png"
                  videoConstraints={videoConstraints}
                  className=""
                />
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <div style={{ textAlign: "center" }} className="flex gap-2">
                  <Button
                    style={{ ...button_style, width: "60%", margin: "auto" }}
                    onClick={() => captureImage(2)}
                  >
                    Prendre une photo
                  </Button>
                  {showSwitchCameraButton && (
                    <Button
                      style={{ ...button_style, width: "60%", margin: "auto" }}
                      onClick={switchCamera}
                    >
                      Changer de caméra
                    </Button>
                  )}
                </div>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default UploadImages;
