import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";

const WelcomePage = () => {
  var isMobileScreen = 0;
  if (window.innerWidth < 450) {
    isMobileScreen = 1;
  }

  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col min-h-screen ${
        isMobileScreen ? "" : "py-4"
      } justify-between items-center w-full`}
    >
      <Header isBackButtonEnables="false" />
      <div className="flex flex-col gap-10 w-full max-w-screen-lg mx-auto px-4">
        <div className="w-full flex justify-center items-center">
          <div className="w-full h-[300px] p-5 bg-[#dbe3f2] rounded-3xl flex flex-col justify-start items-center gap-5 mx-4">
            <p className="text-2xl text-[#818080] font-calibri font-[580]">
              Disclamer Message
            </p>
            <div className="w-full h-[50px] border-y-2 border-dashed border-[#818080]"></div>
          </div>
        </div>

        <button
          className="py-4 w-full max-w-[300px] bg-[#01B051] text-white font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl mx-auto rounded-2xl font-calibri"
          onClick={() => {
            navigate("/start");
          }}
        >
          Valider
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default WelcomePage;
