import Footer from "../footer/Footer";
import Header from "../header/Header";
import SmallCard from "../utilities/SmallCard";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CandidateList = () => {

  var isMobileScreen = 0;
  if (window.innerWidth < 450) {
    isMobileScreen = 1;
  }

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [candidateData, setCandidateData] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState("");
  useEffect(() => {
    const fetchCandidateList = async () => {
      try {
        const response = await axios.get(`${apiUrl}/candidate`);
        if (response.data.statusCode === 200) {
          setCandidateData(response.data.data);
        }
      } catch (error) {
        console.log("Échec de la récupération de la liste des candidats", error);
      }
    };
    fetchCandidateList();
  }, []);

  const handleCandidateSelect = (candidateId) => {
    setSelectedCandidate(candidateId);
  };

  const handleClick = () => {
    if (selectedCandidate) {
      navigate(`/candidateList/details/${selectedCandidate}`);
    } else {
      toast.error("Veuillez sélectionner un candidat.", { autoClose: 2000 });
    }
  };

  return (
    <div className="flex flex-col justify-between items-center min-h-screen w-full">

      <Header isBackButtonEnables="true" backButtonPath="/validate/voterConfirm"/>

      <div className="flex flex-col gap-4 items-center justify-center h-full text-center">
        <p className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-600 text-center font-semibold font-calibri mb-10 lg:max-w-[800px] max-w-[350px] md:max-w-[600px]">
          Sélectionner ci-dessous votre candidat :
        </p>
        <div className={`flex flex-wrap gap-4 justify-center lg:grid-cols-4 md:grid-cols-2 md:w-auto ${isMobileScreen ? 'w-100' : 'w-50'}`}>
          {candidateData &&
            candidateData
              .slice() 
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((obj) => (
                <SmallCard
                  key={obj._id}
                  image={obj.candidateImage }
                  name={obj.name}
                  partyName={obj.partyName}
                  id={obj._id}
                  onSelect={() => handleCandidateSelect(obj._id)}
                  isSelected={selectedCandidate === obj._id}
                />
              ))}
        </div>

        <button
          className="py-4 w-full max-w-[250px] bg-[#01B051] text-white font-semibold text-xl md:text-2xl lg:text-3xl xl:text-4xl mx-auto rounded-2xl font-calibri"
          onClick={handleClick}
        >
          Valider
        </button>
      </div>
      <Footer />
    </div>
  );
};
export default CandidateList;
