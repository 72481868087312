import React from 'react';

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-10 backdrop-blur-sm">
      <div className="inline-block h-28 w-28 animate-spin rounded-full border-[6px] border-solid border-[#01B051] border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
        <span className="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0 clip:rect(0,0,0,0)">Chargement...</span>
      </div>
    </div>
  );
}

export default Loader;