import Footer from "../footer/Footer";
import Header from "../header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackButton from "../../assets/BackButton.PNG";

const CandidateDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/candidate/${id}`);
        if (response.data.statusCode === 200) {
          setDetails(response.data.data);
        }
      } catch (error) {
        console.log("Échec de la récupération des détails du candidat", error);
      }
    };
    fetchCandidateDetails();
  }, []);

  const handleVoteSubmission = async () => {
    try {
      const voterId = localStorage.getItem("voterId");
      const formToBeSent = new FormData();
      formToBeSent.append("voterId", voterId);
      formToBeSent.append("candidateId", id);

      const response = await axios.post(
        `${apiUrl}/candidate/vote`,
        formToBeSent,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.statusCode === 200) {
        localStorage.removeItem("voterId");
        toast.success("Vous avez voté avec succès", { autoClose: 2000 });
        navigate("/success");
        localStorage.removeItem("voterId");
        localStorage.removeItem("id");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message || "Requête invalide", {
          autoClose: 2000,
        });
      }
      console.log("Échec de la publication du vote", error);
    }
  };

  return (
    <div className="flex flex-col justify-between items-center gap-4">
      
      <Header isBackButtonEnables="true" backButtonPath="/candidateList"/>

      <div className="flex flex-col gap-3 h-65 items-center">
        <div className="w-full flex justify-center items-center">
          <p className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-600 text-center font-semibold font-calibri mb-10 lg:max-w-[800px] max-w-[350px] md:max-w-[600px]">
          Merci de confirmer votre choix de candidat
          </p>
        </div>
        <div className="w-full max-w-[70%] md:max-w-[380px] lg:max-w-[350px] border border-[#01B051] rounded-[20px] shadow-md">
          <div className="flex flex-col justify-center items-center gap-1 p-3 rounded-sm">
            <img
              src={details?.candidateImage}
              alt="voter card"
              className="rounded-t-[20px] max-h-[400px] object-cover"
              style={{ maxWidth: "100%", width: "auto" }}
            />
            <p className="font-calibri text-lg text-gray-700">
              {/* <span className="font-bold">Nom: </span> */}
              {details?.name}
            </p>
            <p className="font-calibri text-lg text-gray-700">
              <span className="font-bold">Organisation: </span>
              <br/>
              {details?.partyName}
            </p>
          </div>
        </div>
        <button
          className="py-4 w-full max-w-[70%] md:max-w-[300px] bg-[#01B051] text-white font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl mx-auto rounded-2xl font-calibri"
          onClick={handleVoteSubmission}
        >
          Confirmer
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default CandidateDetails;
