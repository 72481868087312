import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Validate from "./components/validater/Validate";
import VoterConfirm from "./components/VoterConfirm/VoterConfirm";
import CandidateList from "./components/candidatesList/CandidateList";
import CandidateDetails from "./components/candidateDetail/CandidateDetails";
import Success from "./components/LastPage/Success";
import UploadImages from "./components/uploadPhotos/UploadImages";
import { ToastContainer } from "react-toastify";
import WelcomePage from "./components/welcomPage/welcome";
import ProtectedRoutes from "./components/utilities/ProtectedRoute";
function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/start" element={<Home />} />

          <Route path="/validate" element={<Validate />} />

          <Route element={<ProtectedRoutes />}>
            <Route path="/uploadImages" element={<UploadImages />} />
            <Route path="/validate/voterConfirm" element={<VoterConfirm />} />
            <Route path="/candidateList" element={<CandidateList />} />
            <Route
              path="/candidateList/details/:id"
              element={<CandidateDetails />}
            />
          </Route>

          <Route path="/success" element={<Success />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
