import logo from "../../assets/logo.PNG";
import BackButton from "../../assets/BackButton.PNG";

import { useNavigate } from "react-router-dom";

const Header = (props) => {

  const navigate = useNavigate();

  return (

    <div className="w-full flex items-center justify-between px-4">
      <div className="w-full flex items-center justify-center">
        {
          props.isBackButtonEnables === 'true' && <img
            src={BackButton}
            alt="Back"
            className="text-left ml-4 md:w-10 lg:w-14 lg:h-[50px] lg:mb-1 cursor-pointer h-[40px] "
            onClick={() => {
              navigate(props.backButtonPath || '/');
            }}
          />
        }
        <div className="w-full h-[90px] flex items-center justify-center mt-8">
          <img src={logo} alt="logo" className="bg-cover mb-6" />
        </div>
      </div>
    </div>
  );
};
export default Header;
