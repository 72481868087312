const Footer = () => {
  return (
    <div className="pt-4 pb-2 w-full flex justify-center items-center h-[100px]">
      <p className="max-w-full text-2xl text-gray-600 text-center font-semibold font-calibri flex flex-col justify-center items-center">
        Election Présidentielle 2024 Sénégal
        <span>Acceuil</span>
      </p>
    </div>
  );
};

export default Footer;
