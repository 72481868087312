import { useState } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Validate = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [idNumber, setIdNumber] = useState("");

  const handleValidate = async () => {
    if (idNumber.trim() === "") {
      toast.error("Veuillez remplir le numéro d'identification de l'électeur", { autoClose: 2000 });
      return;
    }
    try {
     
      const response = await axios.get(`${apiUrl}/user/${idNumber}`);
      // if(response.data.statusCode === 200){
      //   localStorage.setItem("id", idNumber);
      // }
      // if (response.data.statusCode === 200 && response.data.data?.validated) {
      //   localStorage.setItem("voterId", response.data.data.user._id);
      //   setTimeout(() => {
      //     toast.success("Vous avez déjà Validé", { autoClose: 1000 });
      //     navigate("/validate/voterConfirm");
      //   }, 1000);
      // } else 
      if (
        response.data.statusCode === 200 &&
        response.data.data.voter?.isVoted
      ) {
        toast.error(response.data.data.message, { autoClose: 3000 });
        localStorage.removeItem("id");
        setIdNumber("");
        // setTimeout(() => {
        //   navigate("/success");
        // }, 2000);
      } else if (response.data.statusCode === 200) {
        localStorage.setItem("id", idNumber);
        navigate("/uploadImages", { state: { id: idNumber } });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message || "Requête invalide", {
          autoClose: 1000,
        });
      } else {
        toast.error("Échec de la validation de l'ID", { autoClose: 2000 });
      }
      console.error("Échec de la validation de l'ID", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen py-4 justify-between items-center w-full">
      <Header isBackButtonEnables="true" backButtonPath="/start"/>

      <div className="flex flex-col gap-10 justify-center items-center w-full max-w-screen-lg mx-auto px-4">
        <div className="lg:min-w-[500px] md:min-w-[400px] min-w-[300px] bg-[#F2F2F2] rounded-2xl items-center flex text-center focus:outline-none">
          <input
            type="text"
            placeholder="Entrer votre numéro de carte d’électeur ici"
            value={idNumber}
            onChange={(e) => setIdNumber(e.target.value)}
            className={
              "w-full px-4 md:px-6 py-4 md:py-6 rounded-2xl text-sm md:text-base lg:text-xl xl:text-2xl font-semibold font-calibri leading-normal border border-black placeholder-[#6B7280] focus:ring-0 focus:border focus:border-[#684D94]"
            }
            style={{ backgroundColor: "#FFFFFF" }}
          />
        </div>
        <button
          className="py-4 w-full max-w-[300px] bg-[#01B051] text-white font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl mx-auto rounded-2xl font-calibri"
          onClick={handleValidate}
        >
          Valider
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default Validate;
