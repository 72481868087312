
const SmallCard = ({ image, name, partyName, id, onSelect, isSelected }) => {

  var isMobileScreen = 0;
  if (window.innerWidth < 450) {
    isMobileScreen = 1;
  }

  return (
    <div
      className={`${isMobileScreen ? 'w-180' : 'w-190'} border-[3px] rounded-[20%] shadow-md cursor-pointer px-2 py-2 ${
        isSelected ? "ring-2 ring-[#01B051]" : ""
      }`}
      onClick={() => onSelect(id)}
    >
      <div
        className="flex flex-col justify-center items-center gap-1 p-1 rounded-sm"
        title={`${name}\norganisation: ${partyName}`}
      >
        <img
          src={image}
          alt="Candidate Image"
          className="rounded-t-[20%] h-[120px] bg-transparent bg-cover"
        />
        <p className="font-calibri text-lg text-gray-700">
          {name}
        </p>
        <p className="font-calibri text-lg text-gray-700">
         
          {partyName}
        </p>
      </div>
    </div>
  );
};

export default SmallCard;
