import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";

const Home = () => {
const navigate = useNavigate();
  return (
    <div className="flex flex-col min-h-screen py-4 justify-between items-center w-full">
      <Header isBackButtonEnables="false"/>
      <div className="flex flex-col gap-10 w-full max-w-screen-lg mx-auto px-4">
        <div className="w-full flex justify-center items-center">
        <p className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-600 text-center font-semibold font-calibri mb-10 lg:max-w-[800px] max-w-[350px] md:max-w-[600px]">
          Pour voter merci tout d’abord de s’identifier en cliquant sur le bouton S’identifier.
        </p>
        </div>
        {/* need to navigate on welcome screen */}
        <button
          className="cursor-pointer py-4 w-full max-w-[300px] bg-[#01B051] text-white font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl mx-auto rounded-2xl font-calibri"
        onClick={()=>{
           navigate("/")
        }}
        >
          Acceuil
        </button>
        <button
          className="cursor-auto py-4 w-full max-w-[300px] bg-gray-300 text-white font-semibold text-2xl md:text-3xl lg:text-4xl xl:text-5xl mx-auto rounded-2xl font-calibri"
        >
          Voter
        </button>
      </div>

      <Footer />
    </div>
  );
};
export default Home;
